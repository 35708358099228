import * as React from "react";

// styles
const pageStyles = {
  color: "#232129",
  paddingTop: "96px",
  paddingBottom: "96px",
  paddingLeft: "20px",
  paddingRight: "20px",
  paddingBottom: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
  marginTop: 0,
  marginRight: "auto",
  marginBottom: 0,
  marginLeft: "auto",
  maxWidth: "900px",
};

const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
};

const paragraphStyles = {
  marginBottom: 48,
  fontSize: "25px",
  lineHeight: "32px",
  textAlign: "justify",
};

// markup
const PrivacyPolicy = () => {
  return (
    <main style={pageStyles}>
      <title>Política de privacidad</title>
      <h1 style={headingStyles}>Política de privacidad</h1>
      <p style={paragraphStyles}>
        Dotpem creó la aplicación Mercadia como una aplicación comercial. Este
        SERVICIO es proporcionado por Dotpem SAC y está destinado a ser
        utilizado tal cual. Esta página se utiliza para informar a los
        visitantes sobre nuestras políticas con la recopilación, el uso y la
        divulgación de información personal si alguien decide utilizar nuestro
        Servicio. Si elige utilizar nuestro Servicio, entonces acepta la
        recopilación y el uso de información en relación con esta política. La
        información personal que recopilamos se utiliza para proporcionar y
        mejorar el servicio. No usaremos ni compartiremos su información con
        nadie excepto como se describe en esta Política de Privacidad. Los
        términos utilizados en esta Política de privacidad tienen el mismo
        significado que en nuestros Términos y condiciones, a los que se puede
        acceder en Mercadia a menos que se defina lo contrario en esta Política
        de privacidad.
      </p>
      <h2>Recopilación y uso de información</h2>
      <p style={paragraphStyles}>
        Para una mejor experiencia, mientras utiliza nuestro Servicio, es
        posible que le solicitemos que nos proporcione cierta información de
        identificación personal, que incluye, entre otros, nombre y apellidos,
        número de celular, ubicación. La información que solicitamos será
        retenida por nosotros y utilizada como se describe en esta política de
        privacidad. La aplicación utiliza servicios de terceros que pueden
        recopilar información utilizada para identificarlo. Enlace a la política
        de privacidad de los proveedores de servicios externos utilizados por la
        aplicación
      </p>
      <p style={paragraphStyles}>
        - Servicios de Google Play - Google Analytics para Firebase - Firebase
        Crashlytics
      </p>
      <h2>Dato de registro</h2>
      <p style={paragraphStyles}>
        Queremos informarle que cada vez que utiliza nuestro Servicio, en caso
        de error en la aplicación, recopilamos datos e información (a través de
        productos de terceros) en su teléfono llamado Datos de registro. Estos
        datos de registro pueden incluir información como la dirección de
        Protocolo de Internet ("IP") de su dispositivo, el nombre del
        dispositivo, la versión del sistema operativo, la configuración de la
        aplicación cuando utiliza nuestro Servicio, la hora y fecha de su uso
        del Servicio y otras estadísticas.
      </p>
      <h2>Cookies</h2>
      <p style={paragraphStyles}>
        Las cookies son archivos con una pequeña cantidad de datos que se
        utilizan comúnmente como identificadores únicos anónimos. Estos se
        envían a su navegador desde los sitios web que visita y se almacenan en
        la memoria interna de su dispositivo. Este Servicio no utiliza estas
        "cookies" explícitamente. Sin embargo, la aplicación puede utilizar
        códigos de terceros y bibliotecas que utilizan "cookies" para recopilar
        información y mejorar sus servicios. Tiene la opción de aceptar o
        rechazar estas cookies y saber cuándo se envía una cookie a su
        dispositivo. Si decide rechazar nuestras cookies, es posible que no
        pueda utilizar algunas partes de este Servicio.
      </p>
      <h2>Proveedores de servicio</h2>
      <p style={paragraphStyles}>
        Podemos emplear empresas e individuos de terceros por las siguientes
        razones: - Para facilitar nuestro Servicio; - Para proporcionar el
        Servicio en nuestro nombre; - Para realizar servicios relacionados con
        el servicio; o - Para ayudarnos a analizar cómo se utiliza nuestro
        Servicio. Queremos informar a los usuarios de este Servicio que estos
        terceros tienen acceso a su Información personal. El motivo es realizar
        las tareas que se les asignen en nuestro nombre. Sin embargo, están
        obligados a no divulgar ni utilizar la información para ningún otro
        propósito.
      </p>
      <h2>Seguridad</h2>
      <p style={paragraphStyles}>
        Valoramos su confianza al proporcionarnos su información personal, por
        lo que nos esforzamos por utilizar medios comercialmente aceptables para
        protegerla. Pero recuerde que ningún método de transmisión a través de
        Internet o método de almacenamiento electrónico es 100% seguro y
        confiable, y no podemos garantizar su absoluta seguridad.
      </p>
      <h2>Enlaces a otros sitios</h2>
      <p style={paragraphStyles}>
        Este Servicio puede contener enlaces a otros sitios. Si hace clic en un
        enlace de un tercero, se le dirigirá a ese sitio. Tenga en cuenta que
        estos sitios externos no son operados por nosotros. Por lo tanto, le
        recomendamos encarecidamente que revise la Política de privacidad de
        estos sitios web. No tenemos control ni asumimos ninguna responsabilidad
        por el contenido, las políticas de privacidad o las prácticas de sitios
        o servicios de terceros.
      </p>
      <h2>Privacidad de los niños</h2>
      <p style={paragraphStyles}>
        Estos Servicios no se dirigen a ninguna persona menor de 13 años. No
        recopilamos a sabiendas información de identificación personal de niños
        menores de 13 años. En el caso de que descubramos que un niño menor de
        13 años nos ha proporcionado información personal, la eliminamos
        inmediatamente de nuestros servidores. Si usted es padre o tutor y sabe
        que su hijo nos ha proporcionado información personal, comuníquese con
        nosotros para que podamos realizar las acciones necesarias.
      </p>
      <h2>Cambios a esta política de privacidad</h2>
      <p style={paragraphStyles}>
        Es posible que actualicemos nuestra Política de privacidad de vez en
        cuando. Por lo tanto, le recomendamos que revise esta página
        periódicamente para ver si hay cambios. Le notificaremos de cualquier
        cambio publicando la nueva Política de privacidad en esta página. Esta
        política es efectiva a partir del 2021-03-01
      </p>
      <h2>Contáctenos</h2>
      <p style={paragraphStyles}>
        Si tiene alguna pregunta o sugerencia sobre nuestra Política de
        privacidad, no dude en contactarnos en support@mercadia.app
      </p>
    </main>
  );
};

export default PrivacyPolicy;
